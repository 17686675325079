<template>
  <div class="throughput">
    <head-nav></head-nav>
    <div class="throughput-banner">
      <img
        v-for="(item, index) in throughputBanner"
        :key="index"
        :src="item.imgs"
        alt=""
      >
    </div>
    <div class="throughput-wrap">
      <div class="throughput-list">

        <div class="throughput-list-imgs">
          <div
            class="throughput-list-img"
            v-for="(item, index) in $t('throughput.list-1')"
            :key="index"
            @click.stop="onClickOpen(item.id)"
          >
            <img
              :src="item.img"
              alt=""
            >

            <transition name="nameoftransition">
              <div
                v-if="detailId === item.id"
                class="listEnlargeImg"
              >
                <img
                  src="@/assets/image/x.png"
                  class="iconfont icon-cha"
                  @click.stop="onClickClose()"
                >

                <div class="list-img">

                  <div class="img-info">
                    <div
                      v-for="(items ,index) in item.list"
                      :key="index"
                    >
                      <h3>{{items.title}}</h3>
                      <div>
                        <img
                          :src="items.img"
                          alt=""
                        >
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </transition>

            <div
              class="listEnlargeImg-mask"
              :style="isMaskShow?'opacity: 1;z-index:9;':''"
            ></div>

          </div>
        </div>
      </div>

    </div>
    <footers></footers>
  </div>
</template>

<script>
import banner from '@/assets/image/throughput/banner.png';

import HeadNav from './head-nav';
import Footers from './footers';

export default {
  components: {
    HeadNav,
    Footers,
  },
  data() {
    return {
      detailId: 0,
      isMaskShow: false,
      throughputBanner: [
        {
          id: 1,
          imgs: banner,
        },
      ],
    };
  },

  methods: {
    onClickOpen(ID) {
      if (ID === '1' || ID === '4' || ID === '5' || ID === '8') {
        this.detailId = ID;
        this.isMaskShow = true;
        this.$store.commit('SET_LOCK_BODY_SCROLL', true);
      }
    },
    onClickClose() {
      this.detailId = 0;
      this.isMaskShow = false;
      this.$store.commit('SET_LOCK_BODY_SCROLL', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.nameoftransition-enter-active,
.nameoftransition-leave-active {
  opacity: 1;
  transform: scale(0);
}
.nameoftransition-enter,
.nameoftransition-leave-to {
  opacity: 0;
}
.throughput {
  &-banner {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
  .throughput-wrap {
    padding: 40px 30px 100px 30px;
    background: #f8f8f8;

    .throughput-list {
      &-content {
        line-height: 40px;
        font-size: 28px;
        color: #646464;
      }

      &-imgs {
        margin-top: 30px;
        width: 100%;
        height: 1800px;
        display: flex;
        position: relative;
        overflow: hidden;

        .throughput-list-img {
          width: 344.5px;
          position: absolute;

          .listEnlargeImg-mask {
            cursor: default;
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.1);
            opacity: 0;
            z-index: -1;
          }

          .listEnlargeImg {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: 9999;
            transition: all 0.3s;

            &::-webkit-scrollbar {
              width: 12px;
              height: 12px;
            }

            .icon-cha {
              position: absolute;
              left: 44%;
              bottom: 5%;
              color: #fff;
              width: 80px;
              height: 80px;
              z-index: 999;
            }

            .list-img {
              position: absolute;
              margin-left: auto;
              max-width: 977px;
              border-radius: 3px;
              background: white;
              box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
                rgba(15, 15, 15, 0.2) 0px 15px 40px;
              top: 36px;
              left: 31px;
              right: 31px;
              margin-right: auto;
              height: calc(100% - 240px);
              overflow: auto;
              border-radius: 20px;

              &::-webkit-scrollbar {
                display: none;
              }
            }

            .img-info {
              width: 100%;
              h3 {
                text-align: center;
                margin: 20px 0;
              }

              img {
                width: 100%;
                height: auto;
              }
              .content{
                line-height: 1.5;
              }
            }
          }

          &:nth-child(1) {
            top: 0;
            left: 0;
          }

          &:nth-child(2) {
            top: 0;
            right: 0;
          }

          &:nth-child(3) {
            top: 300px;
            left: 0;
          }

          &:nth-child(4) {
            top: 300px;
            right: 0;
          }

          &:nth-child(5) {
            top: 600px;
            left: 0;
          }

          &:nth-child(6) {
            top: 600px;
            right: 0;
          }

          &:nth-child(7) {
            top: 900px;
            left: 0;
          }

          &:nth-child(8) {
            top: 900px;
            right: 0;
          }

          &:nth-child(9) {
            top: 1200px;
            left: 0;
          }

          &:nth-child(10) {
            top: 1200px;
            right: 0;
          }
          &:nth-child(11) {
            top: 1500px;
            left: 0;
            width: 100%;
          }

          img {
            width: 100%;
            height: 300px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .throughput .throughput-wrap .throughput-list-imgs .throughput-list-img {
    width: 288px;
  }
}
</style>
